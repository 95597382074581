import React, { Component } from "react";
import styled from "styled-components";
import { A, Box, H2, H4, H6, Small } from "@upsolve/ui";
import { get } from "lodash";
import { graphql } from "gatsby";
import { EVENTS } from "@upsolve/shared";

import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import ArticleTile from "../components/Learn/ArticleTile.div";
import ImageInContext from "../components/Media/ImageInContext";
import LearnListingPage from "../components/Learn/LearnListingPage.div";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "AuthorsDirectory",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

class AuthorsDirectory extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const authors = this.props.data.allContentfulAuthor.edges.map((e) => e.node);
    return (
      <TopLevelLayout persistentCta="Chat with an Expert for Free">
        <div className="index-container">
          <SEO
            title="Authors | Upsolve"
            description="Contributing writers and editors for Upsolve"
            image="https://upsolve.org/images/meta-legalaid.png"
            url="https://upsolve.org/authors/"
            jsonLD={[
              {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Authors",
                    item: "https://upsolve.org/authors/",
                  },
                ],
              },
            ]}
          />
          <MainHeader trackingPageContext={trackingPageContext} />
          <main>
            <LearnListingPage>
              <div className="learn__image" />
              <div className="learn__header">
                <H2 as="h1">Upsolve Writers & Editors</H2>
                <H6 as="h2">
                  If you've had questions about bankruptcy, you likely read an article online from one of our amazing
                  writers!
                </H6>
              </div>
              <br />
              <div className="learn__listing">
                {authors.map((author) => (
                  <AuthorTile key={author.id}>
                    <div>
                      {author.image != null && (
                        <div className="author-headshot">
                          <ImageInContext
                            className="author-image"
                            alt={get(author, "name", "")}
                            src={get(author, "image.image.file.url")}
                            fluid={get(author, "image.image.fluid")}
                          />
                        </div>
                      )}
                      <H4>
                        <a href={`/authors/${author.slug}/`}>{author.name}</a>
                      </H4>
                      {author.role && <Small>{author.role}</Small>}
                      <Box margin="4px">
                        <A href={`/authors/${author.slug}/`}>Read More →</A>
                      </Box>
                    </div>
                  </AuthorTile>
                ))}
              </div>
            </LearnListingPage>
          </main>
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}

const AuthorTile = styled(ArticleTile)`
  max-width: 540px;
  margin: 1em auto;
  padding: 1em 4em;
  text-align: center;
  .author-headshot {
    height: 80px;
    max-height: 80px;
    width: 80px;
    max-width: 80px;
    margin: 0 auto;
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    & > * {
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }
`;

export default AuthorsDirectory;

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query AuthorDirectoryQuery {
    allContentfulAuthor(sort: { fields: [slug], order: ASC }) {
      edges {
        node {
          ...AuthorNode
        }
      }
    }
  }
`;
